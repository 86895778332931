import { render, staticRenderFns } from "./publicDetail.vue?vue&type=template&id=8b045e40&scoped=true"
import script from "./publicDetail.vue?vue&type=script&lang=js"
export * from "./publicDetail.vue?vue&type=script&lang=js"
import style0 from "./publicDetail.vue?vue&type=style&index=0&id=8b045e40&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b045e40",
  null
  
)

export default component.exports